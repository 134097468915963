import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
// import '@/assets/main.less';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'lib-flexible/flexible'
Vue.prototype.$VideoJs = videojs;

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
let options = {
  fullscreenEl: false, //控制是否显示右上角全屏按钮
  closeEl: false, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: true, //控制是否显示放大缩小按钮
   arrowEl: false, //左右按钮
  counterEl: true, //控制是否显示左上角图片数量按钮
  tapToToggleControls: false, //点击应切换控件的可见性
  clickToCloseNonZoomable: true, //点击图片应关闭图库，仅当图像小于视口的大小时
  indexIndicatorSep: ' / '//图片数量的分隔符
}

Vue.use(preview, options)
Vue.use(preview)

import VueAwesomeSwiper from 'vue-awesome-swiper';
Vue.use(VueAwesomeSwiper)

/* import { swiperAnimateCache, swiperAnimate, clearSwiperAnimate } from "@/assets/swiper.animate1.0.3.min.js"
Vue.prototype.$swiperAnimateCache = swiperAnimateCache
Vue.prototype.$swiperAnimate = swiperAnimate
Vue.prototype.$clearSwiperAnimate = clearSwiperAnimate */




// 微信开放标签
// Vue.config.ignoredElements = ['wx-open-launch-app']

import global from "@/utils/global";
Vue.prototype.global = global;
// Vue.prototype.appid = appid;
// import '@/router/permission'

Vue.config.productionTip = false
import { Toast } from 'vant';
Vue.use(Vant);
Vue.use(Toast); //轻提示
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
